import { DeliverySettingsType } from '@enums/delivery-settings-type.enum';
import { PaymentType } from '@enums/payment-type.enum';
import { Order } from './order.model';

export class FinalOrder {
  comment?: string;
  paymentType?: PaymentType;
  phone?: string;
  usePoints!: number;
  pointsRatio?: number;
  delivery!: Delivery;
  orders!: Order[];

  constructor(data: FinalOrder) {
    Object.assign(this, data);
  }
}

export interface Delivery {
  type?: DeliverySettingsType;
  value?: any;
}
