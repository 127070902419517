import { Order } from '@models/order.model';
import { GetOrderAdditionsPrice } from './get-order-additions-price.function';

/**
 * Calculates the total price of a single order.
 *
 * @param order - The order object containing the dish, quantity, and additions.
 * @returns The total price of the order.
 */
export function GetSingleOrderPrice(order: Order): number {
  const quantity = order.quantity!;
  const promotion = order.dish!.promotion;

  let additionsTotalPrice = 0;

  if (order.additions) {
    additionsTotalPrice = GetOrderAdditionsPrice(order.additions, quantity);
  }

  const dishPrice = (promotion ? promotion.price.discounted : parseFloat(order.dish!.price!)) * quantity;

  return additionsTotalPrice + dishPrice;
}
